import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/vuex/store";

Vue.config.productionTip = false;

import ContentLayout from "./layouts/Content.vue";
import SplashLayout from "./layouts/Splash.vue";

Vue.component("content-layout", ContentLayout);
Vue.component("splash-layout", SplashLayout);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#jowett-me");
