
















import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["navigationIsInFocus"])
  },
  methods: {
    ...mapActions(["toggleNavigation"])
  }
})
export default class Burger extends Vue {}
