








import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/Logo.vue";
import Navigation from "@/components/Navigation.vue";
import Splash from "@/layouts/Splash.vue";

@Component({
  components: {
    Logo,
    Navigation,
    Splash
  }
})
export default class Home extends Vue {}
