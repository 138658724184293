






import { Component, Vue } from "vue-property-decorator";

@Component({
  computed: {
    layout() {
      return this.$route.meta.layout || "content-layout";
    }
  }
})
export default class App extends Vue {}
