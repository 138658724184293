













import { Component, Vue } from "vue-property-decorator";
import NavigationLink from "@/components/NavigationLink.vue";
import Burger from "@/components/Burger.vue";

@Component({
  components: { Burger, NavigationLink },
  props: {
    showBurger: {
      type: Boolean,
      required: true
    }
  }
})
export default class Navigation extends Vue {}
