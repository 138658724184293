









import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/Logo.vue";
import Header from "@/components/Header.vue";
import { mapGetters } from "vuex";

@Component({
  components: { Header, Logo },
  computed: {
    ...mapGetters(["navigationIsInFocus"])
  }
})
export default class Content extends Vue {}
