import Vuex from "vuex";
import Vue from "vue";
import axios from "axios";

Vue.use(Vuex);

interface Form {
  sender: {
    name: string;
    email: string;
  };
  message: string;
}

export default new Vuex.Store({
  state: {
    navigation: {
      focus: false
    },
    message: {
      failed: false,
      failureMessage: "",
      sending: false,
      sent: false
    }
  },

  getters: {
    messageSending: state => {
      return state.message.sending;
    },

    messageSendFailed: state => {
      return state.message.failed;
    },

    messageSent: state => {
      return state.message.sent;
    },

    navigationIsInFocus: state => {
      return state.navigation.focus;
    }
  },

  mutations: {
    closeNavigation: state => {
      state.navigation.focus = false;
    },

    openNavigation: state => {
      state.navigation.focus = true;
    },

    startMessageSend: state => {
      state.message.failed = false;
      state.message.failureMessage = "";
      state.message.sent = false;
      state.message.sending = true;
    },

    messageSentSuccess: state => {
      state.message.failed = false;
      state.message.failureMessage = "";
      state.message.sending = false;
      state.message.sent = true;
    },

    messageSentFailure: (state, errorMessage) => {
      state.message.failed = true;
      state.message.failureMessage = errorMessage;
      state.message.sending = false;
      state.message.sent = false;
    }
  },

  actions: {
    closeNavigation: context => {
      if (context.state.navigation.focus) {
        context.commit("closeNavigation");
      }
    },

    sendMessage: (context, formData: Form) => {
      context.commit("startMessageSend");

      axios
        .post<Form>(
          process.env.VUE_APP_API_HOST + "/send-message",
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
              "X-API-Key": process.env.VUE_APP_API_KEY
            }
          }
        )
        .then(() => {
          context.commit("messageSentSuccess");
        })
        .catch(() => {
          context.commit("messageSentFailure", "Unable to send message");
        });
    },

    toggleNavigation: context => {
      if (context.state.navigation.focus) {
        context.commit("closeNavigation");
      } else {
        context.commit("openNavigation");
      }
    }
  }
});
