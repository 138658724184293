














import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";

@Component({
  props: {
    link: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions(["closeNavigation"])
  }
})
export default class NavigationLink extends Vue {}
