import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "@/pages/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { layout: "splash-layout" }
  },
  {
    path: "/rich-jowett",
    name: "Rich Jowett",
    component: () => import("@/pages/Rich.vue")
  },
  {
    path: "/get-in-touch",
    name: "Get in Touch",
    component: () => import("@/pages/Contact.vue")
  },
  {
    path: "/linked-in",
    name: "LinkedIn",
    component: () => import("@/pages/LinkedIn.vue")
  },
  {
    path: "/github",
    name: "Github",
    component: () => import("@/pages/Github.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
