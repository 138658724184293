






import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    title: {
      type: String,
      required: true
    }
  }
})
export default class Logo extends Vue {}
