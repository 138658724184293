




















import { Component, Emit, Vue } from "vue-property-decorator";
import Logo from "./Logo.vue";
import Navigation from "@/components/Navigation.vue";
import Burger from "@/components/Burger.vue";
import { mapGetters } from "vuex";

@Component({
  components: { Burger, Navigation, Logo },
  props: {
    title: {
      type: String,
      required: true
    },
    showBurger: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(["navigationIsInFocus"])
  }
})
export default class Header extends Vue {
  active = false;

  @Emit("headerActiveChanged")
  navigationActiveChanged(active: boolean) {
    this.active = active;
  }
}
