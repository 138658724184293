






import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/Logo.vue";
import Navigation from "@/components/Navigation.vue";
@Component({
  components: { Navigation, Logo }
})
export default class Splash extends Vue {}
